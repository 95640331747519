// React Redux
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

// Actions
import { PAYMENT_ACTIONS } from '../../store/actions';

// Components
import Button from '../../shared/Button';

// Material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

// Styles
import { customStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';

// Icons
import CheckIcon from '@material-ui/icons/Check';

// Functions
import { getItem } from '../../utils/localStorage.service';
import { isRtl } from '../../translations/locale';
import storePaymentInfo from '../../utils/storePaymentInfo';


class Thanks extends React.PureComponent {

  componentDidMount() {
    const { getUserInfo, intl, paymentInfo, setPaymentInfo, userInfo } = this.props;
    const { token, tenant_id } = getItem('data', 'tenant_id');

    if(!paymentInfo) storePaymentInfo({intl, setPaymentInfo});

    if(!userInfo) getUserInfo({ token,  tenant_id });
  }

  back = () => {
    this.props.history.goBack();
  };

  next = ({questions}) => {
    const { history, userInfo: { phone, birth_year_master } } = this.props;
    history.push({
      pathname: '/redirect',
      state: { phone, birth_year_master, questions }
    });
  }

  render() {
    const { classes, payment, paymentInfo, priceInfo: { priceWithDiscount } } = this.props;
    const { payme_transaction_card_brand: card_brand } = payment;
    const case_id = paymentInfo?.case_id;


    const {
      case_id: case_id_local,
      card_brand: card_brand_local
    } = getItem('case_id', 'card_brand');

    return (
      <Container className={ classes.container }>
        <Box className={classes.title}>
          <FormattedMessage id="app.Thanks.approval" />
        </Box>

        <Grid container alignItems="flex-start" spacing={5} className={classes.description}>
          <Grid item xs={9}>
            <Box className={classes.cardType} >
              <FormattedMessage
                id="app.Payment.details.card.type"
                values={{type: card_brand?.toUpperCase() || card_brand_local?.toUpperCase()}}
              />
            </Box>

            <Box className={classes.cardType} >
              <span className={classes.sign} >
                <FormattedMessage id="app.Payment.sign" />
              </span>
              {!!priceWithDiscount && ` ${priceWithDiscount}`}
            </Box>

            <Box className={classes.tariffItem} >
              <FormattedMessage id={`app.Thanks.description.time`}/>
              <Box>
                <CheckIcon className={classes.CheckIcon}/>
              </Box>
            </Box>

            <Box className={classes.tariffItem} >
              <FormattedMessage id={`app.ServiceBlock.tariff.for`}/>
              <Box>
                <CheckIcon className={classes.CheckIcon}/>
              </Box>
            </Box>

            <Box className={classes.tariffItem} >
              <FormattedMessage id={`app.Thanks.tariff.addition.common`}/>
              <Box>
                <CheckIcon className={classes.CheckIcon}/>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={3} className={classes.tariffType} />
        </Grid>

        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} className={classes.caseTitle} >
            <FormattedMessage id={`app.Thanks.case.title`}/>
          </Grid>
          <Grid item xs={12} className={classes.case}>
            <FormattedMessage id={`app.Thanks.case.id`} values={{id: case_id || case_id_local}} />
          </Grid>
          <Grid container justify="center" direction={isRtl() ? 'row' : 'row-reverse'}>
            <Grid item xs={5} className={classes.questions}>
              <Button onClick={this.next.bind(this,{ questions: true })} className={classes.questionsButton}>
                <FormattedMessage id={`app.Thanks.questions.common.title`} />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.blockButton}>
          <Button onClick={this.next.bind(this, { questions: false })}>
            <FormattedMessage id="app.Thanks.button" />
          </Button>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    payment: state.payment.data,
    paymentInfo: state.payment.paymentInfo,
    userInfo: state.payment.userInfo,
    priceInfo: state.payment.priceInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentInfo: (paymentInfo) =>
      dispatch({
        type: PAYMENT_ACTIONS.setPaymentInfo,
        payload: paymentInfo
      }),
    getUserInfo: (token) =>
      dispatch({
        type: PAYMENT_ACTIONS.getUserInfoRequest,
        payload: token
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(customStyles)(injectIntl(Thanks)));
