import { PAYMENT_ACTIONS } from '../actions';

const initState = {
  loadingPriceInfo: false,
  priceInfo: {},

  loadingUserInfo: false,
  userInfo: '',

  loadingPayment: false,
  data: {},
  paymentInfo: null,
  error: null,

  loadingTokenization: false,
  tokenizationData: {},
  isTokenSaved: false,
};

const paymentReducer = (state = initState, action) => {
  switch (action.type) {

    // Price info
    case PAYMENT_ACTIONS.getPriceRequest:
      return {
        ...state,
        loadingPriceInfo: true,
      };
    case PAYMENT_ACTIONS.getPriceSuccess:
      return {
        ...state,
        loadingPriceInfo: false,
        priceInfo: action.payload,
      };
    case PAYMENT_ACTIONS.getPriceFailure:
      return {
        ...state,
        loadingPriceInfo: false,
        error: action.payload,
      };

    // User info
    case PAYMENT_ACTIONS.getUserInfoRequest:
      return {
        ...state,
        loadingUserInfo: true,
      };
    case PAYMENT_ACTIONS.getUserInfoSuccess:
      return {
        ...state,
        loadingUserInfo: false,
        userInfo: action.payload,
      };
    case PAYMENT_ACTIONS.getUserInfoFailure:
      return {
        ...state,
        loadingUserInfo: false,
        error: action.payload,
      };

    // Payment
    case PAYMENT_ACTIONS.makePaymentRequest:
      return {
        ...state,
        loadingPayment: true,
      };
    case PAYMENT_ACTIONS.makePaymentSuccess:
      return {
        ...state,
        loadingPayment: false,
        data: action.payload,
      };
    case PAYMENT_ACTIONS.makePaymentFailure:
      return {
        ...state,
        loadingPayment: false,
        error: action.payload,
      };

    // Tokenization
    case PAYMENT_ACTIONS.tokenization:
      return {
        ...state,
        loadingTokenization: true,
      };
    case PAYMENT_ACTIONS.tokenizationSuccess:
      return {
        ...state,
        loadingTokenization: false,
        tokenizationData: action.payload,
      };
    case PAYMENT_ACTIONS.tokenizationFailure:
      return {
        ...state,
        loadingTokenization: false,
        error: action.payload,
      };

    case PAYMENT_ACTIONS.saveTokenRequest:
      return {
        ...state,
        loadingTokenization: true,
      };
    case PAYMENT_ACTIONS.saveTokenSuccess:
      return {
        ...state,
        loadingTokenization: false,
        isTokenSaved: action.payload.success,
        paymentInfo: { ...state.paymentInfo, case_id: action.payload.case_id }
      };
    case PAYMENT_ACTIONS.saveTokenFailure:
      return {
        ...state,
        loadingTokenization: false,
        error: action.payload,
      };

    // Payment info
    case PAYMENT_ACTIONS.setPaymentInfo:
      return {
        ...state,
        paymentInfo: action.payload
      };
    default:
      return { ...state };
  }
};

export default paymentReducer;
