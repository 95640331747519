import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SNACKBAR_ACTIONS } from '../../store/actions';
import { Typography } from '@material-ui/core';
import { isRtl } from '../../translations/locale';

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    direction: isRtl() ? 'rtl' : 'ltr',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    '& .MuiAlert-action': {
      padding: isRtl() ? '0 16px 0 0' : '0 0 0 16px',
    },
    '& .MuiAlert-icon': {
      margin: isRtl() ? '0 0 0 16px' : '0 16px 0 0',
    },
  }
}));

export default function CustomizedSnackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, status, message } = useSelector(state => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({
      type: SNACKBAR_ACTIONS.setSnackbarInfo,
      payload: {
        status,
        message,
        isOpen: false
      }
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert className={classes.alert} onClose={handleClose} severity={status} >
          <Typography align={isRtl() ? 'right' : 'left'}>
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}
