import { getItem } from '../utils/localStorage.service';

const getCoupon = () => {
  let coupon;
  const queryParams = new URLSearchParams(location.search);
  if (/card|thanks/.test(location.pathname)) {
    coupon = getItem('coupon').coupon;
  } else {
    coupon = queryParams.has('coupon') && queryParams.get('coupon');
  }

  return coupon;
};

const getPriceWithDiscount = ({ price, discount }) => {
  const decimal = discount / 100;
  const discountValue = price * decimal;
  const resultPrice = price - discountValue;
  return Number.isInteger(resultPrice) ? resultPrice : resultPrice.toFixed(2);
};

export { getCoupon, getPriceWithDiscount };