import React from 'react';

import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const Main = () => {

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: '100vh' }}
    >
      <Box>
        <Typography variant="h2">
          <FormattedMessage id="app.main" />
        </Typography>
      </Box>
    </Grid>
  );
};

export default Main;
