const getItem = (...names) => {

  const result = names.reduce(((acc, name) => {
    if (name === 'data'){
      const data = JSON.parse(localStorage.getItem(name));
      Object.entries(data).forEach(([ key, value ]) => acc[key] = value);
    } else {
      acc[name] = localStorage.getItem(name);
    }

    return acc;
  }), {});


  return result;
};

const saveItem = (...items) => {

  items.forEach(([ key, value ]) => localStorage.setItem(key, value));

  return null;
};

export { getItem, saveItem };