import { isRtl } from '../../translations/locale';


const getFieldsOptions = (intl) => {
  const base = {'text-align':  isRtl() ? 'right' : 'left'};

  const cvcOptions = {
    placeholder: 'XXX',
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.cvc.required` }),
      invalid: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.cvc.invalid` })
    },
    styles: {
      invalid: {
        'color': 'red'
      },
    }
  };

  const cardExpirationOptions = {
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.expiration.required` }),
      invalid: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.expiration.invalid` })
    },
    styles: {
      invalid: {
        'color': 'red'
      },
    }
  };

  const cardNumberOptions = {
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.card.required` }),
      invalid: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.card.invalid` })
    },
    styles: {
      invalid: {
        'color': 'red'
      },
    }
  };
  const nameOptions = {
    placeholder: ' ',
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.name.required` }),
    },
    styles: {
      base,
      invalid: {
        'color': 'red'
      },
    }
  };

  const socialIdOptions = {
    placeholder: ' ',
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.socialId.required` }),
    },
    styles: {
      invalid: {
        'color': 'red'
      },
    }
  };

  const surnameOptions = {
    placeholder: ' ',
    messages: {
      required: intl.formatMessage({ id: `app.Payment.hosted.fields.messages.surname.required` }),
    },
    styles: {
      base,
      invalid: {
        'color': 'red'
      },
    }
  };

  return {
    cvcOptions,
    cardExpirationOptions,
    cardNumberOptions,
    nameOptions,
    socialIdOptions,
    surnameOptions
  };
};

export default getFieldsOptions;