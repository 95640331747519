import { createStyles } from '@material-ui/core';
import { isRtl } from '../../translations/locale';

export const customStyles = (theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      background: '#ffffff',
      padding: '20px',
      [theme.breakpoints.up(768)]: {
        padding: '2.5rem'
      }
    },
    item: {
      height: '100%'
    },
    mainTitle: {
      fontFamily: 'LieblingProBold',
      fontSize: '1.75rem',
      lineHeight: 0.86,
      letterSpacing: '0.4px',
      textAlign: 'right',
      color:'#272735',
      display: 'flex',
      justifyContent: isRtl() ? 'flex-start' : 'flex-end',
      flexDirection: 'row-reverse',
      marginTop: '25%',
      '@media (max-width: 320px) and (max-height: 568px)': {
        fontSize: '1.2rem',
      }
    },
    title: {
      display: 'flex',
      justifyContent: isRtl() ? 'flex-end' : 'flex-start',
      margin: '30px 0px 16px',
      fontFamily: 'LieblingProBold',
      fontSize: '1.75rem',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      color: '#272735',
      [theme.breakpoints.up(768)]: {
        marginBottom: '1.3rem'
      },
      '& + div': {
        marginBottom: '1.5rem'
      }
    },
    purpose: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0 0 20px',
      fontFamily: 'Heebo',
      fontSize: '0.75rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#95959d',
      [theme.breakpoints.up(768)]: {
        marginBottom: '0.4rem'
      },
      [theme.breakpoints.up(1024)]: {
        marginBottom: '0.1rem'
      },
    },
    backgroundMain: {
      width: '100%',
      position: 'absolute',
      top: '-8%',
      right: '20%',
      transform: isRtl() ? 'none' : 'scale(-1, 1)'
    },
    backgroundStripes: {
      width: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      transform: isRtl() ? 'none' : 'scale(-1, 1)',
      overflow: 'hidden',
    },
  });
