import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const StyledButton = withStyles({
  root: {
    background: '#000',
    '&:hover': {
      background: '#b9b9c3',
    },
    width: '100%',
    height: '55px',
    color: '#fff',
    borderRadius: '8px',
    fontFamily: 'LieblingProBold',
    fontSize: '1rem'
  },
})(Button);

const ButtonComponent = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      {children}
    </StyledButton>
  );
};

export default ButtonComponent;
