// Axios
import { axiosInstance } from '../../core/axios';

// Actions
import { PAYMENT_ACTIONS, SNACKBAR_ACTIONS } from '../actions';

// Saga
import { put, takeLatest, call, delay} from 'redux-saga/effects';

// Functions
import { saveItem } from '../../utils/localStorage.service';
import { getCoupon, getPriceWithDiscount } from '../../utils/amount';
import { getCaseUuid } from '../../utils/case';


const API = process.env.REACT_APP_API_URL;

console.log('API', API);
export function* getUserInfo() {

  yield takeLatest(PAYMENT_ACTIONS.getUserInfoRequest, function* (action) {
    const { token, tenant_id } = action.payload;
    saveItem(['tenant_id', tenant_id]);

    try {
      // Validation of token
      const result = yield call(() =>
        axiosInstance.get(`/payments/validate/${token}`, { timeout: 30000 })
      );
      yield put({
        type: PAYMENT_ACTIONS.getUserInfoSuccess,
        payload: result.data,
      });

      if(!result.data.access_token) throw ('Wrong token');
      saveItem(['access_token', result.data.access_token]);

      // Getting price
      yield put({type: PAYMENT_ACTIONS.getPriceRequest});

    } catch (error) {
      console.log(`error`, error);
      yield put({
        type: SNACKBAR_ACTIONS.setSnackbarInfo,
        payload: {
          isOpen: true,
          status: 'error',
          message: error.response?.data?.message || error
        }
      });
      yield put({
        type: PAYMENT_ACTIONS.getUserInfoFailure,
        payload: error
      });
    }
  });
}


export function* makePayment() {

  yield takeLatest(PAYMENT_ACTIONS.makePaymentRequest, function* (action) {
    const { history, intl, ...paymentData} = action.payload;
    try {
      const result = yield call(() =>
        axiosInstance.post('/payments/transaction', paymentData, { timeout: 5000 })
      );

      const { message, case_id, payme_transaction_card_brand } = result.data;

      if (!message) {
        const milliseconds = 5000;
        yield delay(milliseconds);
        yield put({
          type: PAYMENT_ACTIONS.makePaymentSuccess,
          payload: result.data,
        });

        saveItem(['case_id', case_id], ['card_brand', payme_transaction_card_brand]);

        history.push('/thanks');

      } else {

        console.log('error :>> ', message);
        yield put({
          type: PAYMENT_ACTIONS.makePaymentFailure,
          payload: message,
        });
        yield put({
          type: SNACKBAR_ACTIONS.setSnackbarInfo,
          payload: {
            isOpen: true,
            status: 'error',
            // message: message
            message: intl.formatMessage({ id: `app.Payment.tokenization.error.details` })
          }
        });

      }

    } catch (error) {
      console.log('error :>> ', error.response.data.message);
      yield put({ type: PAYMENT_ACTIONS.makePaymentFailure, payload: error });
      yield put({
        type: SNACKBAR_ACTIONS.setSnackbarInfo,
        payload: {
          isOpen: true,
          status: 'error',
          // message: error.response.data.message
          message: intl.formatMessage({ id: `app.Payment.tokenization.error.details` })
        }
      });
    }
  });
}

export function* saveToken() {

  yield takeLatest(PAYMENT_ACTIONS.saveTokenRequest, function* (action) {
    const { history, intl, ...paymentData } = action.payload;
    try {
      const result = yield call(() =>
        axiosInstance.post('/payments/token', paymentData, { timeout: 15000 })
      );

      if(!result?.data?.success) throw (`${intl.formatMessage({ id: `app.Payment.tokenization.error.saving.token` })}`);
      const milliseconds = 5000;
      yield delay(milliseconds);

      yield put({
        type: PAYMENT_ACTIONS.saveTokenSuccess,
        payload: result.data,
      });
      saveItem(['case_id', result.data.case_id]);

      history.push('/thanks');
    } catch (error) {
      console.log('error :>> ', error.response?.data?.message || error);
      yield put({ type: PAYMENT_ACTIONS.saveTokenFailure, payload: error.response?.data?.message || error });
      yield put({
        type: SNACKBAR_ACTIONS.setSnackbarInfo,
        payload: {
          isOpen: true,
          status: 'error',
          message: intl.formatMessage({ id: `app.Payment.tokenization.error.saving.token` })
        }
      });
    }
  });
}

export function* getPrice() {

  yield takeLatest(PAYMENT_ACTIONS.getPriceRequest, function* () {
    const case_uuid = getCaseUuid();
    const coupon = getCoupon();

    const params = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      timeout: 5000
    };

    try {
      if(coupon) {
        const result = yield call(() =>
          axiosInstance.post(`${API}/payments/check-coupon/${case_uuid}`, { coupon }, params)
        );
        const { price, discount } = result.data;
        yield put({
          type: PAYMENT_ACTIONS.getPriceSuccess,
          payload: { ...result.data, priceWithDiscount: getPriceWithDiscount({price, discount})},
        });
        saveItem(['coupon', coupon]);
      } else {
        localStorage.removeItem('coupon');
        const result = yield call(() =>
          axiosInstance.get(`${API}/payments/price`, params)
        );
        const { price } = result.data;

        yield put({
          type: PAYMENT_ACTIONS.getPriceSuccess,
          payload: { price, discount: 0, priceWithDiscount: +price,  },
        });
      }
    } catch (error) {
      yield put({
        type: SNACKBAR_ACTIONS.setSnackbarInfo,
        payload: {
          isOpen: true,
          status: 'error',
          message: error.response.data.message
        }
      });
      yield put({
        type: PAYMENT_ACTIONS.getPriceFailure,
        payload: error
      });
    }
  });
}

