// React Redux
import React from 'react';

// Material-ui
import Box from '@material-ui/core/Box';

// Styles
import { withStyles } from '@material-ui/core/styles';

// Utils
import { isRtl } from '../../translations/locale';

const Title = withStyles({
  root: {
    fontFamily: 'Heebo',
    fontSize: '0.75rem',
    lineHeight: 2,
    textAlign: isRtl() ? 'right' : 'left',
    color: props => {
      // @ts-ignore
      const { color } = props;
      // @ts-ignore
      return color ? color : '#95959d';
    },
    marginBottom: '4px',
    marginTop: props => {
      // @ts-ignore
      const { marginTop } = props;
      // @ts-ignore
      return marginTop ? marginTop : '0px';
    },
  },
})(props => {
  const { classes, ...other } = props;
  return <Box className={classes.root} {...other} />;
});

export default Title;
