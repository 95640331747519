import { getItem } from '../utils/localStorage.service';
import translations from './i18n-locales';

const getTranslations = () => {
  const translatedLanguagesList = ['en', 'he'];
  const language = getLanguage();
  const result = translatedLanguagesList.includes(language) ? translations[language] : translations.en;

  return result;
};

const getLanguage = () => {
  let language;
  const queryParams = new URLSearchParams(location.search);

  if (/card|thanks/.test(location.pathname)) {
    language = getItem('language').language;
  } else {
    language = queryParams.has('language') && queryParams.get('language') || 'he';
  }
  return language;
};

const isRtl = () => {
  const language  = getLanguage();
  return /he/.test(language);
};

export { getTranslations, getLanguage, isRtl };