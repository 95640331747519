import React from 'react';

// import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Alert from '../Alert';
import { useLocation } from 'react-router-dom';
import { isRtl } from '../../translations/locale';

const Navigation = withStyles({
  root: {
    width: '100%',
    paddingTop: '16px'
  },
})(Box);

const IconBlock = withStyles({
  root: {
    padding: '12px 0'
  },
})(IconButton);

const isPaymentPage = () => {
  return /card/.test(location.pathname);
};

const NavigationTop = ({ ...props }) => {
  const location = useLocation();
  const { onBackClick } = props;

  return (
    <Navigation>
      {/card/.test(location.pathname) &&
        <Typography align={isRtl() ? 'right' : 'left'}>
          <IconBlock aria-label="back" onClick={onBackClick} >
            {isRtl() ?
              (<ArrowForwardIcon htmlColor={isPaymentPage() ? 'white' : 'black'}/>) :
              (<ArrowBackIcon htmlColor={isPaymentPage() ? 'white' : 'black'}/>)
            }
          </IconBlock >
        </Typography>
      }
      <Alert />
    </Navigation>
  );
};

export default NavigationTop;
