export const PAYMENT_ACTIONS = Object.freeze({
  getPriceRequest: 'GET_PRICE_REQUEST',
  getPriceSuccess: 'GET_PRICE_SUCCESS',
  getPriceFailure: 'GET_PRICE_FAILURE',

  getUserInfoRequest: 'GET_USER_INFO_REQUEST',
  getUserInfoSuccess: 'GET_USER_INFO_SUCCESS',
  getUserInfoFailure: 'GET_USER_INFO_FAILURE',

  makePaymentRequest: 'MAKE_PAYMENT_REQUEST',
  makePaymentSuccess: 'MAKE_PAYMENT_SUCCESS',
  makePaymentFailure: 'MAKE_PAYMENT_FAILURE',

  tokenization: 'TOKENIZATION_REQUEST',
  tokenizationSuccess: 'TOKENIZATION_SUCCESS',
  tokenizationFailure: 'TOKENIZATION_FAILURE',

  saveTokenRequest: 'SAVE_TOKEN_REQUEST',
  saveTokenSuccess: 'SAVE_TOKEN_SUCCESS',
  saveTokenFailure: 'SAVE_TOKEN_FAILURE',

  setPaymentInfo: 'SET_PAYMENT_INFO',

});