// @ts-nocheck
import { createStyles } from '@material-ui/core';
import { isRtl } from '../../translations/locale';

export const customStyles = (theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      position: 'fixed',
      backgroundColor: props => props && props?.paymentInfo?.trackColor,
      [theme.breakpoints.up(768)]: {
        paddingLeft: '1.8rem',
        paddingRight: '1.8rem',
      },
    },
    cardContainer: {
      height: '100%',
      margin: '16px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      overflow: 'overlay',
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#000',
      height: '40px',
      color: '#fff',
      borderRadius: '20px',
      fontFamily: 'LieblingProRegular',
      fontSize: '0.9rem',
      marginTop: '0.9rem'
    },
    stripes: {
      width: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      transform: isRtl() ? 'none' : 'scale(-1, 1)',
      overflow: 'hidden'
    }
  });
