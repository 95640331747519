import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const BodyContainer = withStyles({
  root: {
    width: '100vw',
    height: '75%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    // @ts-ignore
    overflow: (props) => props.overflow ? props.overflow : 'unset',
    // @ts-ignore
    background: (props) => !props.maincolor ? '#fff' : `${props.maincolor}`,
    borderTopRightRadius: '50px',
    borderTopLeftRadius: '50px',
    '@media (min-width: 1366px)': {
      maxWidth: '1280px',
      left: '50%',
      transform:'translate(-50%)'
    },
    '@media (max-width: 320px) and (max-height: 568px)': {
      height: '70%',
    }
  },
})(props => {
  const { classes, ...other } = props;
  return <Box className={classes.root} {...other} />;
});

const Body = ({ children, ...props }) => {
  return (
    <BodyContainer {...props}>
      {children}
    </BodyContainer>
  );
};

export default Body;
