// React Redux
import React from 'react';
import { connect } from 'react-redux';

// Actions
import { PAYMENT_ACTIONS } from '../../store/actions';

// Components
import Body from '../../shared/Body';
import BackgroundStripes from './components/BackgroundStripes';
import NavigationTop from '../../shared/NavigationTop';
import ServiceContent from './components/ServiceContent';

// Material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

// Styles
import { customStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';

// Tariffs
import { getMainColor, color } from './tariffs';

// Functions
import { saveItem } from '../../utils/localStorage.service';
import { FormattedMessage } from 'react-intl';
import { getLanguage } from '../../translations/locale';

class ServiceLevel extends React.PureComponent {

  componentDidMount = () => {
    const { match: {params: { token, uuid }}, getUserInfo} = this.props;
    const tenant_id = new URLSearchParams(location.search).get('tenant_id');

    saveItem(['language', getLanguage()]);
    saveItem(['data', JSON.stringify({token, uuid})]);

    getUserInfo({ token, tenant_id });
  }

  selectTariff = (amount, description) => {
    const { match: {params: {uuid}}, history, setPaymentInfo, userInfo: { phone } } = this.props;
    const { color: trackColor } = getMainColor();

    const paymentInfo = {
      amount,
      description,
      trackColor,
      currency: 'ILS',
      uuid
    };

    setPaymentInfo(paymentInfo);

    phone && history.push('/card');
  };

  back = () => {
    console.log('to go somewhere');
  }

  render() {
    const { classes, priceInfo: { priceWithDiscount, discount, price } } = this.props;

    return (
      <Container className={classes.container}>
        <Box>
          <Box className={classes.backgroundStripes}>
            <BackgroundStripes width={innerWidth}/>
          </Box>
          <NavigationTop />
          <Box className={classes.mainTitle}>
            <FormattedMessage id="app.Payment.header" />
          </Box>
        </Box>

        <Body maincolor={color} overflow={'auto'}>
          <ServiceContent
            amount={priceWithDiscount}
            discount={discount}
            price={price}
            selectTariff={this.selectTariff}
          />
        </Body>


      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    paymentInfo: state.payment.paymentInfo,
    userInfo: state.payment.userInfo,
    priceInfo: state.payment.priceInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaymentInfo: (paymentInfo) =>
      dispatch({
        type: PAYMENT_ACTIONS.setPaymentInfo,
        payload: paymentInfo
      }),
    getUserInfo: (token) =>
      dispatch({
        type: PAYMENT_ACTIONS.getUserInfoRequest,
        payload: token
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(customStyles)(ServiceLevel));
