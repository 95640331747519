// React, Redux
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';

// Components
import Box from '@material-ui/core/Box';
import Button from '../../../../shared/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Title from '../../../../shared/Title';

// Styles
import { customStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';

// Functions
import { isRtl } from '../../../../translations/locale';


const CardForm = ({ isHostedFieldsMounted, makePayment, classes, loadingPayment, loadingTokenization }) => {
  const { handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(makePayment)} >

      <Grid container spacing={1} className={classes.blockIndentTop}>
        <Grid item xs={12} >
          <Title>
            <FormattedMessage id="app.Payment.details.card.number" />
          </Title>
          <Box id="cardNumber" className={classes.hostedFields} />
        </Grid>
      </Grid>

      <Grid container spacing={1} justify="space-between" className={classes.blockIndentTop} >
        <Grid item xs={6} >
          <Title>
            <FormattedMessage id="app.Payment.details.card.expiration" />
          </Title>
          <Box id="cardExpiration" className={classes.hostedFields} />
        </Grid>

        <Grid item xs={6} >
          <Title>
            <FormattedMessage id="app.Payment.details.card.cvv" />
          </Title>
          <Box id="CVC" className={classes.hostedFields} />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justify="space-between"
        direction={isRtl() ? 'row-reverse' : 'row'}
        className={classes.blockIndentTop}
      >
        <Grid item xs={6} >
          <Title>
            <FormattedMessage id="app.Payment.details.card.owner.name" />
          </Title>
          <Box id="name" className={classes.hostedFields} />
        </Grid>

        <Grid item xs={6} >
          <Title>
            <FormattedMessage id="app.Payment.details.card.owner.surname" />
          </Title>
          <Box id="surname" className={classes.hostedFields} />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.blockIndentTop}>
        <Grid item xs={12} >
          <Title>
            <FormattedMessage id="app.Payment.details.socialId" />
          </Title>
          <Box id="socialId" className={classes.hostedFields} />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.blockIndentTop}>
        <Grid item xs={12}>
          <Title>
            <FormattedMessage id="app.Payment.save.card" />
          </Title>
        </Grid>

      </Grid>
      <Grid container spacing={1} className={classes.blockButton} >
        <Button
          type="submit"
          disabled={!isHostedFieldsMounted || loadingTokenization || loadingPayment}
        >
          {loadingTokenization || loadingPayment ?
            (<CircularProgress size={24} />) :
            (<FormattedMessage id="app.Payment.button" />)}
        </Button>
      </Grid>

    </form>
  );
};

export default withStyles(customStyles)(CardForm);