import * as React from 'react';

function BackgroundStripes(props) {
  return (
    <svg
      data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 9444"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox={`0 0 375 ${props.width}`}
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2329"
            fill="none"
            d="M0 0H375V395.479H0z"
          />
        </clipPath>
        <clipPath id="b">
          <path
            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 31074"
            d="M7.092 7.257c-4.833 3.647-5.809 10.43-6.29 16.465a255.1 255.1 
            0 008.981 90.154c2.589 9.065 6.253 18.724 14.339 23.571 5.24 
            3.142 11.564 3.755 17.651 4.295 8.547.759 66.084.757 86.227-1.028 2.833-.251 
            5.741-.523 8.293-1.778 5.824-2.864 8.149-9.862 9.454-16.22 3.794-18.485 
            5.342-84.117 3.688-97.835-.416-3.453-1.451-13.807-12.933-17.943C113.201-1.455 21.01-3.248 7.092 7.257z"
            fill="none"
            stroke="#7fcdc9"
            strokeWidth={1}
          />
        </clipPath>
        <clipPath id="c">
          <path
            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 31073"
            d="M-5925.977 7384.2c-32.1-8.667-92.978-7.2-108.389 69.194 0 0-9.246 
            117.112 61.131 120.688 38.442 1.953 88.354-28.026 95.032-105.491 2.022-23.448 7.718-69.404-47.774-84.391z"
            transform="translate(6034.766 -7380.61)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2495"
        transform="translate(11)"
        clipPath="url(#a)"
      >
        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2494">
          <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2493">
            <g
              data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2492"
              clipPath="url(#b)"
              transform="translate(-50.757 -55.81) translate(295.367)"
              opacity={0.309}
            >
              <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2491"
                transform="translate(-2.303 .291)"
                fill="none"
                stroke="#7fcdc9"
                strokeMiterlimit={10}
                strokeWidth={1.712}
              >
                <path
                  data-name="\u041B\u0438\u043D\u0438\u044F 130"
                  transform="translate(0 83.263)"
                  d="M0 0L157.783 0"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2490">
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 131"
                    transform="translate(0 8.326)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 132"
                    transform="translate(0 4.163)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 133"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 134"
                    transform="translate(0 20.816)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 135"
                    transform="translate(0 16.652)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 136"
                    transform="translate(0 12.489)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 137"
                    transform="translate(0 33.305)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 138"
                    transform="translate(0 29.142)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 139"
                    transform="translate(0 24.979)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 140"
                    transform="translate(0 45.795)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 141"
                    transform="translate(0 41.631)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 142"
                    transform="translate(0 37.468)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 143"
                    transform="translate(0 58.284)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 144"
                    transform="translate(0 54.12)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 145"
                    transform="translate(0 49.958)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 146"
                    transform="translate(0 70.773)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 147"
                    transform="translate(0 66.61)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 148"
                    transform="translate(0 62.447)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 149"
                    transform="translate(0 79.099)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 150"
                    transform="translate(0 74.937)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 151"
                    transform="translate(0 95.752)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 152"
                    transform="translate(0 91.589)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 153"
                    transform="translate(0 87.426)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 154"
                    transform="translate(0 108.241)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 155"
                    transform="translate(0 104.078)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 156"
                    transform="translate(0 99.915)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 157"
                    transform="translate(0 120.731)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 158"
                    transform="translate(0 116.568)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 159"
                    transform="translate(0 112.405)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 160"
                    transform="translate(0 133.22)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 161"
                    transform="translate(0 129.057)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 162"
                    transform="translate(0 124.894)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 163"
                    transform="translate(0 145.71)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 164"
                    transform="translate(0 141.546)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 165"
                    transform="translate(0 137.384)"
                    d="M0 0L157.783 0"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2495"
        transform="translate(0 1)"
        clipPath="url(#a)"
      >
        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2494">
          <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2487">
            <g
              data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2486"
              clipPath="url(#c)"
              transform="translate(-78.757 -67.223) translate(0 29.233)"
            >
              <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2485"
                transform="translate(-37.563 -13.322)"
                fill="#1db7af"
              >
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2312"
                  transform="translate(0 13.522)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2313"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2314"
                  transform="translate(0 27.043)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2315"
                  transform="translate(0 40.564)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2316"
                  transform="translate(0 54.086)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2317"
                  transform="translate(0 67.607)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2318"
                  transform="translate(0 81.128)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2319"
                  transform="translate(0 94.65)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2320"
                  transform="translate(0 108.171)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2321"
                  transform="translate(0 121.692)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2322"
                  transform="translate(0 135.214)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2323"
                  transform="translate(0 148.735)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2324"
                  transform="translate(0 162.257)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2325"
                  transform="translate(0 175.778)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2326"
                  transform="translate(0 189.299)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2327"
                  transform="translate(0 202.82)"
                  d="M0 0H228.081V3.867H0z"
                />
                <path
                  data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2328"
                  transform="translate(0 216.342)"
                  d="M0 0H228.081V3.867H0z"
                />
              </g>
            </g>
          </g>
          <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2493">
            <g
              data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2492"
              clipPath="url(#b)"
              transform="translate(-78.757 -67.223) translate(345.367)"
              opacity={0.309}
            >
              <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2491"
                transform="translate(-2.303 .291)"
                fill="none"
                stroke="#7fcdc9"
                strokeMiterlimit={10}
                strokeWidth={1.712}
              >
                <path
                  data-name="\u041B\u0438\u043D\u0438\u044F 130"
                  transform="translate(0 83.263)"
                  d="M0 0L157.783 0"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 2490">
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 131"
                    transform="translate(0 8.326)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 132"
                    transform="translate(0 4.163)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 133"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 134"
                    transform="translate(0 20.816)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 135"
                    transform="translate(0 16.652)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 136"
                    transform="translate(0 12.489)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 137"
                    transform="translate(0 33.305)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 138"
                    transform="translate(0 29.142)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 139"
                    transform="translate(0 24.979)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 140"
                    transform="translate(0 45.795)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 141"
                    transform="translate(0 41.631)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 142"
                    transform="translate(0 37.468)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 143"
                    transform="translate(0 58.284)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 144"
                    transform="translate(0 54.12)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 145"
                    transform="translate(0 49.958)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 146"
                    transform="translate(0 70.773)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 147"
                    transform="translate(0 66.61)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 148"
                    transform="translate(0 62.447)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 149"
                    transform="translate(0 79.099)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 150"
                    transform="translate(0 74.937)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 151"
                    transform="translate(0 95.752)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 152"
                    transform="translate(0 91.589)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 153"
                    transform="translate(0 87.426)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 154"
                    transform="translate(0 108.241)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 155"
                    transform="translate(0 104.078)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 156"
                    transform="translate(0 99.915)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 157"
                    transform="translate(0 120.731)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 158"
                    transform="translate(0 116.568)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 159"
                    transform="translate(0 112.405)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 160"
                    transform="translate(0 133.22)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 161"
                    transform="translate(0 129.057)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 162"
                    transform="translate(0 124.894)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 163"
                    transform="translate(0 145.71)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 164"
                    transform="translate(0 141.546)"
                    d="M0 0L157.783 0"
                  />
                  <path
                    data-name="\u041B\u0438\u043D\u0438\u044F 165"
                    transform="translate(0 137.384)"
                    d="M0 0L157.783 0"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackgroundStripes;
