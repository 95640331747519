// Material-ui
import Box from '@material-ui/core/Box';

// Styles
import { withStyles } from '@material-ui/core/styles';

// Utils
import { isRtl } from '../../../translations/locale';


const sideMargin = isRtl() ? { marginRight: '15px' } : { marginLeft: '15px' };

const Sum = withStyles({
  root: {
    ...sideMargin,
    fontFamily: 'LieblingProBold',
    fontSize: '1.75rem',
    lineHeight: 0.86,
    letterSpacing: '0.4px',
    textAlign: 'right',
    color:'#fff',
    display: 'flex',
    justifyContent: isRtl() ? 'flex-start' : 'flex-end',
    flexDirection: 'row-reverse',
    marginTop: '8%',
  },
})(Box);

export default Sum;
