// Functions
import isObjectEmpty from '../../../utils/isObjectEmpty';
import { addField, checkFieldsAndToggleButtonState } from './fields';

// Options
import getFieldsOptions from '../getFieldsOptions';

import 'allsettled-polyfill';


const initialize = ({intl, fields, toggleButtonDisabledProperty}) => {
  const {
    cvcOptions,
    cardExpirationOptions,
    cardNumberOptions,
    nameOptions,
    surnameOptions,
    socialIdOptions
  } = getFieldsOptions(intl);

  const cardNumber = fields.create('cardNumber', cardNumberOptions);
  const expiration = fields.create('cardExpiration', cardExpirationOptions);
  const cvc = fields.create('cvc', cvcOptions);
  const name = fields.create('payerFirstName', nameOptions);
  const surname = fields.create('payerLastName', surnameOptions);
  const socialId = fields.create('payerSocialId', socialIdOptions);

  Promise.allSettled([
    cardNumber.mount('#cardNumber')
      .then(() => {
        addField('cardNumber');
        console.log('cardNumber was mounted');
      }),
    expiration.mount('#cardExpiration')
      .then(() => {
        addField('cardExpiration');
        console.log('cardExpiration was mounted');
      }),
    cvc.mount('#CVC')
      .then(() => {
        addField('cvc');
        console.log('cvc was mounted');
      }),
    name.mount('#name')
      .then(() => {
        addField('payerFirstName');
        console.log('name was mounted');
      }),
    surname.mount('#surname')
      .then(() => {
        addField('payerLastName');
        console.log('surname was mounted');
      }),
    socialId.mount('#socialId')
      .then(() => {
        addField('payerSocialId');
        console.log('socialId was mounted');
      })
  ])
    .then(() => console.log('all hosted fields were mounted'))
    .catch((error) => console.log('error hosted fields', error));

  cardNumber.on('validity-changed', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
  expiration.on('validity-changed', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
  cvc.on('validity-changed', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
  name.on('keyup', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
  surname.on('keyup', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
  socialId.on('keyup', state => {
    checkFieldsAndToggleButtonState(state, toggleButtonDisabledProperty);
  });
};

const getTokenizationErrorMessage = ({ intl, tokenizationError }) => {
  let message, values;

  if (tokenizationError.validationError) {
    message = 'tokenization.error.validation.main';
    const [detail] = Object.values(tokenizationError.errors) ||
    [ intl.formatMessage({ id: `app.Payment.tokenization.error.validation.default` }) ];
    if (detail) values = { details: detail };
  } else if (!tokenizationError.validationError && !isObjectEmpty(tokenizationError)) {
    if (tokenizationError) values = { details: tokenizationError.payload?.status_error_details };
    message = 'tokenization.error.details';
  } else {
    message = 'tokenization.error.common';
  }

  return { message, values };
};



export { initialize, getTokenizationErrorMessage };