const getDescription = (intl) => {
  return intl.formatMessage({ id: `app.Payment.description` });
};

const getErrorMessage = (intl, message, values) => {
  return intl.formatMessage(
    {id: `app.Payment.${message}`},
    (values && {...values})
  );
};

export { getDescription, getErrorMessage };