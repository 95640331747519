import { getItem } from '../utils/localStorage.service';

export const getCaseUuid = () => {
  let case_uuid;

  if (/card|thanks/.test(location.pathname)) {
    case_uuid = getItem('data').uuid;
  } else {
    const url = new URL(location.href);
    case_uuid = url.pathname.slice(1).split('/')[2];
  }

  return case_uuid;
};
