import { createStyles } from '@material-ui/core';
import { isRtl } from '../../../translations/locale';


// const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
const descriptionProperties = isRtl() ?
  { borderLeft: 'solid 2px #fff', paddingLeft: '5px', } :
  { borderRight: 'solid 2px #fff', };
const checkIconMargin = isRtl() ? { marginLeft: '4px', } : { marginRight: '4px', };
const titleMargin = isRtl() ?
  { marginRight: '1.2rem', marginBottom: '2.5rem' } :
  { marginLeft: '1.2rem', marginBottom: '2.5rem' };
const arrowMargin = isRtl() ? { marginRight: '3.3rem', marginLeft: '2.5rem' } : { marginLeft: '2.5rem', marginRight: '1.9rem' };
const noticeMargin = isRtl() ? { marginLeft: '3.3rem' } : { marginRight: '2.0rem' };
const costBlockMargin = isRtl() ? { marginLeft: '4rem', } : { marginRight: '3.3rem' };
const costBlockItemMargin = isRtl() ? { marginRight: '0.5rem', } : { marginLeft: '0.5rem' };


export const customStyles = () =>
  createStyles({
    arrow: { ...arrowMargin },
    blockButton: {
      marginTop: '10%',
      marginBottom: '10%',
      '@media (min-width: 768px)': {
        marginTop: '7.5%',
      },
      '@media (min-width: 1024px)': {
        marginTop: '11%',
      },
      '& .Mui-disabled': {
        backgroundColor: '#b9b9c3',
        color: '#fff'
      },
    },
    button: {
      display: 'flex',
      justifyContent: isRtl() ? 'flex-start' : 'flex-end'
    },
    container: {
      margin: '16px',
      paddingTop: '5.8rem',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      overflow: 'overlay',
      '@media (max-width: 414px) and (max-height: 896px)': {
        paddingTop: '3.5rem',
      },
      '@media (max-width: 390px) and (max-height:844px)': { // IPhone 12 Pro
        paddingTop: '2.5rem',
      },
      '@media (max-width: 375px) and (max-height:812px)': {
        paddingTop: '2.3rem',
      },
      '@media (max-width: 360px) and (max-height:804px)': {
        paddingTop: '2.5rem',
      },
    },
    costBlock: {
      ...costBlockMargin,
      height: '2.4rem',
      display: 'flex',
      color: '#fff',
      fontFamily: 'Heebo',
      fontWeight: 'normal',
      flexDirection: isRtl() ? 'row': 'row-reverse',
    },
    costBlockItem: {
      ...costBlockItemMargin,
      display: 'flex',
      alignItems: 'flex-end',
      height: '1.6rem',
      lineHeight: '1.6rem',
    },
    costBlockAmount: {
      ...costBlockItemMargin,
      color: '#90FFD5',
      fontFamily: 'LieblingProBold',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      display: 'flex',
      alignItems: 'flex-end',
      height: '1.58rem',
      lineHeight: '1.8rem',
    },
    costContainer: {
      flexDirection: 'column',
      marginTop: '5rem',
      '@media (max-width: 428px) and (max-height:926px)': {
        marginTop: '3.3rem',
      },
      '@media (max-width: 414px) and (max-height: 896px)': {
        marginTop: '4.5rem',
      },
      '@media (max-width: 390px) and (max-height:844px)': { // IPhone 12 Pro
        marginTop: '3.8rem',
      },
      '@media (max-width: 360px) and (max-height:804px)': {
        marginTop: '3.5rem',
      },
      '@media (max-width: 360px) and (max-height:780px)': { // IPhone 12 mini
        marginTop: '2.8rem',
      },
    },
    gridContainer: {
      justifyContent:'space-around',
      flexDirection: isRtl() ? 'row' : 'row-reverse',
    },
    orderBlock: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    descriptionBlock: {
      ...descriptionProperties,
    },
    tariffType: {
      fontFamily: 'LieblingProBold',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2,
      letterSpacing: '0.34px',
      textAlign: isRtl() ? 'right' : 'left',
      color: '#272735'
    },
    tariffItem: {
      display: 'flex',
      flexDirection: isRtl() ? 'row' : 'row-reverse',
      justifyContent: 'flex-end',
      fontFamily: 'Heebo',
      fontSize: '1rem',
      lineHeight: 1.5,
      textAlign: isRtl() ? 'right' : 'left',
      color: '#fff',
      marginBottom: '1.2rem',
    },
    CheckIcon: {
      ...checkIconMargin,
      fontSize: 'inherit',
      color: 'inherit'
    },
    notice: {
      ...noticeMargin,
      height: '1.5rem',
      textAlign: isRtl() ? 'left' : 'right',
      color: '#fff',
      fontSize: '0.8rem',
      fontFamily: 'Heebo',
    },
    title: {
      ...titleMargin,
      textAlign: isRtl() ? 'right' : 'left',
      color: '#fff',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: isRtl() ? 'row' : 'row-reverse',
      '@media (max-width: 390px) and (max-height:844px)': {
        marginBottom: '1rem',
      },
      '@media (max-width: 360px) and (max-height:780px)': {
        marginBottom: '.5rem',
      },
    },
  });

