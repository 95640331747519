import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import paymentReducer  from './store/reducers/payment.reducer';
import snackbarReducer  from './store/reducers/snackbar.reducer';


import { getPrice, getUserInfo, makePayment, saveToken } from './store/sagas';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  payment: paymentReducer,
  snackbar: snackbarReducer,
});

export const history = createBrowserHistory();

// @ts-ignore
const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools || compose;

const routerMW = routerMiddleware(history);

export const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(routerMW, sagaMiddleware)),
);

sagaMiddleware.run(getPrice);
sagaMiddleware.run(getUserInfo);
sagaMiddleware.run(makePayment);
sagaMiddleware.run(saveToken);

export default store;
