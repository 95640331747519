import { SNACKBAR_ACTIONS } from '../actions';

const initialState = {
  isOpen: false,
  status: '',
  message: '',
};

export const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_ACTIONS.setSnackbarInfo: {
      const { isOpen, status, message } = action.payload;
      return {
        ...state,
        isOpen,
        status,
        message,
      };
    }
    default:
      return state;
  }
};

export default snackbarReducer;
