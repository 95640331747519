// React Redux
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

// Actions
import { PAYMENT_ACTIONS, SNACKBAR_ACTIONS } from '../../store/actions';

// Components
import Body from '../../shared/Body';
import CardForm from './components/CardForm';
import NavigationTop from '../../shared/NavigationTop';
import Stripes from './components/Stripes';
import Sum from './components/Sum';
import Title from '../../shared/Title';

// Material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// Styles
import { customStyles } from './styles';
import { withStyles } from '@material-ui/core/styles';

// Functions
import { getErrorMessage } from '../../utils/tariff.js';
import { getItem } from '../../utils/localStorage.service';
import storePaymentInfo from '../../utils/storePaymentInfo';
import { getTokenizationErrorMessage, initialize } from './helpers/hostedFields';
import { getTariffType } from '../ServiceLevel/tariffs';

class Payment extends React.PureComponent {
  // Create global variable to add ability to separate actions of hostedfields.js library to parts: mounting fields, tokenization
  // link to hostedfields.js library: https://github.com/PayMeService/payme-jsapi
  paymeInstance;
  state = { isHostedFieldsMounted: false };

  componentDidMount() {
    const { getUserInfo, intl, paymentInfo, setPaymentInfo, userInfo } = this.props;

    if(!paymentInfo) {
      storePaymentInfo({intl, setPaymentInfo});
    }

    if(!userInfo) {
      const { token, tenant_id } = getItem('data', 'tenant_id');

      getUserInfo({ token, tenant_id });
    }

    this.mountHostedFields();
  }

  toggleButtonDisabledProperty = (value) => {
    this.setState({ isHostedFieldsMounted: value });
  }

  mountHostedFields = () => {
    const isTestMode = process.env.REACT_APP_PAYME_TEST_MODE === 'true';
    // @ts-ignore
    // Mounting fields to app with hostedfields.js library
    this.paymeInstance = window.PayMe.create(process.env.REACT_APP_PAYME_API_KEY, { testMode: isTestMode }).then((instance) => {

      const fields = instance.hostedFields();

      initialize({ fields, toggleButtonDisabledProperty: this.toggleButtonDisabledProperty, intl: this.props.intl });

      return instance;

    }).catch((error) => console.log('error', error));
  };

  back = () => {
    this.props.history.goBack();
  };

  makePayment = () => {
    const {
      intl,
      priceInfo: {priceWithDiscount: amount},
      userInfo: {
        email: payerEmail,
        phone: payerPhone
      },
      setSnackbarInfo,
      startTokenization,
      tokenizationFinishedSuccessfully,
      tokenizationFinishedFailure
    } = this.props;
    const { tariff_type: type } = getTariffType();
    const VALUE_TO_CORRECT_CALCULATION_SUM_ON_PAYME_SERVICE = 100;
    const preparedForSentAmount = amount * VALUE_TO_CORRECT_CALCULATION_SUM_ON_PAYME_SERVICE;

    // Card tokenization with hostedfields.js library
    this.paymeInstance.then(instance => {

      const dataToTokenization = {
        payerEmail,                // payerEmail field wasn't created, we must provide value
        payerPhone,                // payerPhone field wasn't created, we must provide value
        total: {
          label: type,
          amount: {
            currency: 'ILS',
            value: `${preparedForSentAmount}`,
          }
        }
      };

      startTokenization();
      instance.tokenize(dataToTokenization)
        .then(tokenizationResult => {
          tokenizationFinishedSuccessfully(tokenizationResult);
          // Transaction
          // this.makeTransaction(tokenizationResult);
          this.sendTokenDataToSave(tokenizationResult);
        })
        .catch(tokenizationError => {
          tokenizationFinishedFailure(tokenizationError);
          console.error('tokenizationError', JSON.stringify(tokenizationError, null, '\t'));

          const { message, values } = getTokenizationErrorMessage({ intl, tokenizationError });
          setSnackbarInfo('error', getErrorMessage(intl, message, values));
        });
    });
  };

  makeTransaction = (tokenizationData) => {
    const { history, intl, makePayment, userInfo: { patient_id } } = this.props;
    const { uuid: case_uuid } = getItem('data');
    const {
      payerSocialId: social_id,
      token,
      total: {
        amount: { currency, value: sale_price },
        label: tariff_type,
      }
    } = tokenizationData;

    const dataToSend = {
      history,
      intl,
      case_uuid,
      currency,
      patient_id,
      sale_price,
      social_id,
      tariff_type,
      token,
      seller_payme_id: process.env.REACT_APP_PAYME_API_KEY,
    };

    makePayment(dataToSend);
  }

  sendTokenDataToSave = (tokenizationData) => {
    const { history, intl, saveToken, userInfo: { patient_id } } = this.props;
    const { uuid: case_uuid } = getItem('data');
    const {
      payerSocialId: social_id,
      token,
      total: {
        amount: { currency, value: sale_price },
        label: tariff_type,
      }
    } = tokenizationData;

    const dataToSend = {
      history,
      intl,
      case_uuid,
      currency,
      patient_id,
      sale_price,
      social_id,
      tariff_type,
      token,
      seller_payme_id: process.env.REACT_APP_PAYME_API_KEY,
    };

    saveToken(dataToSend);
  }

  render() {
    const { classes, loadingPayment, loadingTokenization, paymentInfo } = this.props;
    const { isHostedFieldsMounted } = this.state;

    if(!paymentInfo) return null;

    return (
      <Container className={classes.container}>
        <Box className={classes.stripes}>
          <Stripes />
        </Box>
        <NavigationTop onBackClick={this.back} />

        <Sum>
          <FormattedMessage id="app.Payment.header" />
        </Sum>

        <Body>
          <Box className={classes.cardContainer} >
            <Box width="90%">
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.headline}>
                  <FormattedMessage id="app.Payment.title" />
                </Grid>
                <Grid item xs={12}>
                  <Title color="#72727e" marginTop="20px">
                    <FormattedMessage id="app.Payment.details.title" />
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  <CardForm
                    isHostedFieldsMounted={isHostedFieldsMounted}
                    makePayment={this.makePayment}
                    loadingPayment={loadingPayment}
                    loadingTokenization={loadingTokenization}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Body>

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentInfo: state.payment.paymentInfo,
    loadingPayment: state.payment.loadingPayment,
    loadingTokenization: state.payment.loadingTokenization,
    userInfo: state.payment.userInfo,
    priceInfo: state.payment.priceInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: (token) =>
      dispatch({
        type: PAYMENT_ACTIONS.getUserInfoRequest,
        payload: token
      }),
    setPaymentInfo: (paymentInfo) =>
      dispatch({
        type: PAYMENT_ACTIONS.setPaymentInfo,
        payload: paymentInfo,
      }),
    setSnackbarInfo: (status, message) =>
      dispatch({
        type: SNACKBAR_ACTIONS.setSnackbarInfo,
        payload: {
          isOpen: true,
          status: status,
          message: message
        }
      }),
    makePayment: (history) =>
      dispatch({
        type: PAYMENT_ACTIONS.makePaymentRequest,
        payload: history,
      }),
    saveToken: (data) =>
      dispatch({
        type: PAYMENT_ACTIONS.saveTokenRequest,
        payload: data,
      }),
    startTokenization: () =>
      dispatch({
        type: PAYMENT_ACTIONS.tokenization,
      }),
    tokenizationFinishedSuccessfully: (tokenizationResult) =>
      dispatch({
        type: PAYMENT_ACTIONS.tokenizationSuccess,
        payload: tokenizationResult,
      }),
    tokenizationFinishedFailure: (tokenizationError) =>
      dispatch({
        type: PAYMENT_ACTIONS.tokenizationFailure,
        payload: tokenizationError,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(customStyles)(injectIntl(Payment)));
