const filledFields = {};

const addField = (name) => {
  filledFields[name] = false;
};

const changeStateForField = (state) => {
  filledFields[state.field] = !state.isEmpty && state.isValid;
};

const isAllFieldsFilled = () => {
  return Object.entries(filledFields).every(([, value]) => value === true);
};

const checkFieldsAndToggleButtonState = (state, toggleButton) => {
  changeStateForField(state);
  toggleButton(isAllFieldsFilled());
};

export { addField, isAllFieldsFilled, changeStateForField, checkFieldsAndToggleButtonState };
