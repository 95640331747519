import { getDescription } from './tariff.js';
import { getMainColor } from '../pages/ServiceLevel/tariffs';
import { getItem } from './localStorage.service';

const storePaymentInfo = ({intl, setPaymentInfo}) => {
  const { uuid } = getItem('data');
  const { color: trackColor } = getMainColor();
  const description = getDescription(intl);

  const info = { description, currency: 'NIS', trackColor, uuid };

  setPaymentInfo(info);
};

export default storePaymentInfo;