import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ServiceLevel from './pages/ServiceLevel';
import Payment from './pages/Payment';
import Thanks from './pages/Thanks';
import Main from './pages/Main';
import './App.css';
import 'inobounce';

export const App = () => {
  return (
    <>
      <Router basename="/payment">
        <Switch>
          <Route path="/card" component={Payment}></Route>
          <Route path="/thanks" component={Thanks}></Route>
          <Route path="/:token/:uuid" exact component={ServiceLevel} />
          <Route path="/" exact component={Main} />
          <Route path="/about" component={() => <h2>ABOUT</h2>} />
          <Route
            path="/redirect"
            component={(props) => {
              const { questions, phone, birth_year_master } = props.location.state;

              if (questions) {
                console.log(`link ---> %s`, `myderma://myskin/profile/questions-answers/${phone}/${birth_year_master}`);
                global.window &&
                  (global.window.location.href = `myderma://myskin/profile/questions-answers/${phone}/${birth_year_master}`);
              } else {
                console.log(`link ---> %s`, `myderma://myskin/profile/cases/${phone}/${birth_year_master}`);
                global.window && (global.window.location.href = `myderma://myskin/profile/cases/${phone}/${birth_year_master}`);
              }
              return null;
            }}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
