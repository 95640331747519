import axios from 'axios';
import { getItem } from '../utils/localStorage.service';

export const TENANT_HEADER = 'tenant-id';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000,
  headers: {},
});

axiosInstance.interceptors.request.use(
  async function(config) {
    const { access_token, tenant_id } = getItem('access_token', 'tenant_id');
    if (access_token !== null) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    if (tenant_id !== null) {
      config.headers[TENANT_HEADER] = tenant_id;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);